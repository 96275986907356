import { Observer, observer } from "mobx-react-lite";
import { useMemo } from "react";

import {
  Checkbox,
  IColumn,
  IDetailsListCheckboxProps,
  Stack
} from "@bps/fluent-ui";
import { hasMore } from "@libs/api/utils/paging.utils.ts";
import { ScrollListener } from "@ui-components/ScrollListener.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import {
  InfiniteScrollListProps,
  SortDirection
} from "./InfiniteScrollList.types.ts";
import { DEFAULT_PAGE_SIZE, useInfiniteScroll } from "./useInfiniteScroll.ts";

/*
Features of InfiniteScrollList

 * Handles infinite scroll internally
  Will call getItems with a paging query to fetch data - no need to keep track
  of paging.

 * Can refresh the list when the keys is update this can be useful for when data is added / changed / removed

 * Handles sorting internally
  Just need to set isSortable on column to allow sorting.  However like paging,
  the API must be set up in correct way for sorting to work.

How to use list with a filter

 Firstly, the getItems function should be in a useCallback.  This function should
 add the filter to the query before it is sent to the API.  The useCallback should
 also have the filter as a dependency as when getItems changes the list will
 refresh itself.

 If you need to have access to the items outside the list, then either store the
 API results (inside the getItems function) before returning it, or use onGetItems
 to get the full results 
*/

export const InfiniteScrollList = observer(
  <Item extends { id: string }>(props: InfiniteScrollListProps<Item>) => {
    const {
      getItems,
      onRenderNoResults,
      refreshKey,
      sort,
      onSort,
      initialSort,
      onGetItems,
      columns,
      checkboxAutomationAttribute,
      displayDefaultCheckBox,
      onRenderCheckbox,
      ...listProps
    } = props;

    const {
      handleScrolledToBottom,
      isLoading,
      isLoadingMore,
      searchResults,
      setSortProps,
      sortProps
    } = useInfiniteScroll(props);

    const { error } = searchResults;

    const enableTrailingShimmer: boolean = !!(
      searchResults.value && hasMore(searchResults.value)
    );

    // add sorting props to columns if isSortable is true
    const _columns = useMemo(
      () =>
        columns?.map(
          ({
            isSortable,
            sortDefaultDirection,
            onRender,
            ...column
          }): IColumn => {
            const isSorted = sortProps?.sortColumn === column.key;
            const isSortedDescending = isSorted
              ? sortProps?.sortDescending
              : undefined;

            const onColumnClick = isSortable
              ? () => {
                  if (isSorted) {
                    if (isSortedDescending) {
                      setSortProps({ sortColumn: column.key });
                    } else {
                      setSortProps({
                        sortColumn: column.key,
                        sortDescending: true
                      });
                    }
                  } else {
                    setSortProps({
                      sortColumn: column.key,
                      sortDescending:
                        sortDefaultDirection === SortDirection.Descending ||
                        undefined
                    });
                  }
                }
              : undefined;

            return {
              showSortIconWhenUnsorted: isSortable,
              onColumnClick,
              ...column,
              onRender: (item: Item) => (
                <Observer>
                  {() => (onRender ? onRender(item) : item[column.key])}
                </Observer>
              ),
              isSorted,
              isSortedDescending
            };
          }
        ),
      [columns, setSortProps, sortProps?.sortColumn, sortProps?.sortDescending]
    );

    const renderCheckbox = (props: IDetailsListCheckboxProps | undefined) => {
      if (displayDefaultCheckBox) {
        return (
          <Checkbox
            {...props}
            styles={{ root: { pointerEvents: "none" } }}
            automationAttribute={checkboxAutomationAttribute || ""}
          />
        );
      }
      if (onRenderCheckbox) {
        return onRenderCheckbox(props);
      }
      return null;
    };

    return (
      <Stack>
        <ShimmeredDetailsList
          errorMessage={error?.message}
          shimmerLines={DEFAULT_PAGE_SIZE}
          stickyHeader // set as true by default
          onShouldVirtualize={() => false}
          onRenderCheckbox={props => renderCheckbox(props)}
          {...listProps}
          items={searchResults.value?.results || []}
          enableShimmer={isLoading}
          enableTrailingShimmer={enableTrailingShimmer}
          columns={_columns}
        />
        <ScrollListener
          searchResults={searchResults}
          onScrolledToBottom={handleScrolledToBottom}
        />
        {!searchResults.value?.results.length &&
          !isLoading &&
          !isLoadingMore &&
          onRenderNoResults &&
          onRenderNoResults()}
      </Stack>
    );
  }
);
